.mainContainer {
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 320px;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  width: 100%;
  margin: 0 0 1rem 0;
}
.darkmodeForget .titleForm {
  color: #FFF;
}
.titleForm {
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #303032;
  margin-top: 30px;
}
.darkmodeForget .subtitle {
  color: #3b8aed;
}

.subtitle {
  font-family: 'Poppins';
  font-size: 0.9rem;
  color: #6E0000;
  text-align: center;
}
.darkmodeForget .confirmationText {
  color: #3b8aed;
}
.confirmationText {
  font-size: 0.9rem;
  color: #6E0000;
  text-align: center;
  margin-top: 1rem;
}
.backToLoginContainer {
  display: flex;
  justify-content: center;
}
.imageContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.contentInput {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #999999;
  padding: 0 0.7rem;
  border-radius: 5px;
  height: 40px;
}
.postSentEmail {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.inboxIcon {
  margin: 0 auto;
}

.darkmodeForget .inputForm {
  color: #FFF;
}
.inputForm {
  border: none;
  width: 100%;
  outline: none;
  font-family: 'Poppins';
  background: none;
}
.backToLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputForm:focus-visible {
  outline: none;
}

.inputForm::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #3e3e3e;
}

.darkmodeForget .inputText label {
  color: #3b8aed;
}
.inputText label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #6E0000;
  line-height: 20px;
}

.inputText input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(153, 153, 153, 0.6);
}
.logo {
  max-width: 90%;
  height: auto;
}
.skeleton {
  width: 100%;
  height: 50px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}
.skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  animation: loading 1.5s infinite linear;
}
.containerButton {
  text-align: center;
}

.containerButton button {
  background: #6E0000;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  height: 50px;
  color: #fff;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@media (max-width: 425px) {
  .logo {
    max-width: 95%;
  }
  .contentContainer {
    padding: 0 2rem;
  }
}
